'use client'

import { Container, LineSeparator, N12Events } from '@news12/storybook-news'

import React from 'react'

import useRegion from '../../hooks/useRegion'

export interface EventsSectionProps {
  calendarId: number
}

const EventsSection = ({ calendarId }: EventsSectionProps) => {
  const { nav } = useRegion()

  const eventsRoute = nav.secondary.Sections['N12 Events']

  return (
    <>
      <Container>
        <LineSeparator margin={['2.25rem auto', '2.5rem auto']} />
      </Container>
      <N12Events title='N12 Events' href={eventsRoute.path} widgetId={calendarId} />
    </>
  )
}

export default EventsSection
